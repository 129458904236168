import { Arrow } from './../arrow'
import { createSvgElement, updateAttributesSvgElement } from '../svg'

export class Block {
  constructor(flowchart) {
    this.flowchart = flowchart

    this.x = 0
    this.y = 0

    this.width = 220
    this.height = 70

    this.element = null

    this.prevBlock = null

    this.nextBlock = null
  }

  getOutput() {
    var rect1 = this.element.getBoundingClientRect()
    var rect2 = this.flowchart.element.getBoundingClientRect()

    return {
      x: rect1.left - rect2.left + 110,
      y: rect1.top - rect2.top + 70
    }
  }

  update() {
    updateAttributesSvgElement(this.element, {
      transform: `translate(${this.x}, ${this.y})`
    })
  }

  create() {
    this.element = createSvgElement('g')
    updateAttributesSvgElement(this.element, {
      transform: `translate(${this.x}, ${this.y})`
    })
  }

  onExecute() { return true }

  beforeOnExecute() { }

  afterOnExecute() { }

  addArrow() {
    var arrow = new Arrow(this.flowchart, this)
    var i; var length = Math.floor(arguments.length / 2)

    for (i = 0; i < length; i++) {
      arrow.addVertex(arguments[i * 2], arguments[i * 2 + 1])
    }

    this.flowchart.addArrow(arrow)

    return arrow
  }

  setNextBlock(block) {
    this.nextBlock = block
  }

  goNextBlock() {
    if (this.nextBlock) {
      this.nextBlock.execute()
    }
  }

  execute() {
    this.beforeExecute()

    const executed = this.onExecute()
    const timeout = new Promise(resolve => setTimeout(() => resolve(), 500))

    return Promise.all([executed, timeout])
      .then(() => this.afterExecute())
      .then(() => this.goNextBlock())
  }

  beforeExecute() {
    updateAttributesSvgElement(this.element, {
      class: 'execute'
    })

    this.beforeOnExecute()
  }

  afterExecute() {
    updateAttributesSvgElement(this.element, {
      class: ''
    })

    this.afterOnExecute()
  }

  setPosition(x, y) {
    this.x = x
    this.y = y
  }

  setSize(width, height) {
    this.width = width
    this.height = height
  }
}
