import { Executor } from '../executor'
import { createSvgElement, updateAttributesSvgElement } from '../svg'
import { Block } from './block'

export class ProcessBlock extends Block {
  constructor(text, flowchart) {
    super(flowchart)

    this.text = text
  }

  create() {
    super.create()

    var rect = createSvgElement('polygon')
    updateAttributesSvgElement(rect, {
      class: 'flowchart-block',
      points: `0,0 ${this.width},0 ${this.width},${this.height} 0,${this.height}`
    })

    var text = createSvgElement('text')
    updateAttributesSvgElement(text, {
      class: 'flowchart-text'
    })
    text.textContent = this.text

    this.flowchart.element.appendChild(this.element)

    this.element.appendChild(rect)
    this.element.appendChild(text)

    var boundingClientRect = text.getBoundingClientRect()

    updateAttributesSvgElement(text, {
      x: 110 - boundingClientRect.width / 2,
      y: boundingClientRect.height / 4 + 35
    })
  }

  onExecute() {
    const data = this.flowchart.variables
    const text = this.text

    const match = text.match(/^([a-zA-Z0-9]+)\s*=\s*(.*)$/)

    const value = new Executor()
      .addVariables(data)
      .execute(`return ${match[2]};`)

    this.flowchart.setVariable(match[1], value)

    return true
  }
}
