const SVG_XMLNS = 'http://www.w3.org/2000/svg'

export function createSvgElement(name) {
  return document.createElementNS(SVG_XMLNS, name)
}

export function updateAttributesSvgElement(element, attributes) {
  for (const name in attributes) {
    const value = attributes[name]

    element.setAttributeNS(null, name, value)
  }
}
