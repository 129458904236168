import { Executor } from '../executor'
import { createSvgElement, updateAttributesSvgElement } from '../svg'
import { Block } from './block'

export class DecisionBlock extends Block {
  constructor(text, flowchart) {
    super(flowchart)

    this.height = 110

    this.text = text

    this.trueOutput = null
    this.falseOutput = null

    this.output = false

    this.decision = false
  }

  create() {
    super.create()

    var rect = createSvgElement('polygon')
    updateAttributesSvgElement(rect, {
      class: 'flowchart-block',
      points: this.height + ',0 ' + this.width + ',' + this.height / 2 + ' ' + this.width / 2 + ',' + this.height + ' 0,' + this.height / 2
    })

    var text = createSvgElement('text')
    updateAttributesSvgElement(text, {
      class: 'flowchart-text'
    })
    text.textContent = this.text

    this.flowchart.element.appendChild(this.element)

    this.element.appendChild(rect)
    this.element.appendChild(text)

    var boundingClientRect = text.getBoundingClientRect()

    updateAttributesSvgElement(text, {
      x: 110 - boundingClientRect.width / 2,
      y: boundingClientRect.height / 4 + 55
    })
  }

  getOutput() {
    var rect1 = this.element.getBoundingClientRect()
    var rect2 = this.flowchart.element.getBoundingClientRect()

    var v = {
      x: rect1.left - rect2.left + ((this.output) ? 0 : 220),
      y: rect1.top - rect2.top + 55
    }

    this.output = !this.output

    return v
  }

  onTrueOutput() {
    this.output = true

    return this
  }

  onFalseOutput() {
    this.output = false

    return this
  }

  setNextBlock(block) {
    if (this.output) { this.trueOutput = block } else { this.falseOutput = block }
  }

  goNextBlock() {
    if (this.decision) {
      if (this.trueOutput) { this.trueOutput.execute() }
    } else {
      if (this.falseOutput) { this.falseOutput.execute() }
    }
  }

  onExecute() {
    const data = this.flowchart.variables
    const text = this.text

    this.decision = new Executor()
      .addVariables(data)
      .execute(`return ${text};`)

    return true
  }
}
