export class Executor {
  constructor() {
    this.variablesNames = []
    this.variablesValues = []
  }

  addVariables(variables) {
    variables.forEach((value, key) => this.addVariable(key, value))

    return this
  }

  addVariable(name, value) {
    this.variablesNames.push(name)
    this.variablesValues.push(value)

    return this
  }

  execute(code) {
    return new Function(...this.variablesNames, code)(...this.variablesValues)
  }
}
