import { Factory } from "./factory";
import "./index.css";
import { createSvgElement } from "./svg";

export default class Flowchart {
  constructor(element) {
    this.element = element !== undefined ? element : null;

    this.blocks = [];

    this.arrows = [];

    this.variables = new Map();

    this.add = new Factory(this);

    this.firstBlock = this.add.start();
  }

  build() {
    this.element.innerHTML = "";

    this.createDefs();

    var i;

    for (i = 0; i < this.blocks.length; i++) {
      this.blocks[i].create();
    }

    for (i = 0; i < this.arrows.length; i++) {
      this.arrows[i].create();
    }
  }

  listenOutput(msg) {}

  listenInput(input, name) {}

  createDefs() {
    const defs = createSvgElement("defs");

    defs.innerHTML =
      '<marker id="arrow" markerWidth="10" markerHeight="10" refX="0" refY="3" orient="auto" markerUnits="strokeWidth"><path d="M0,0 L0,6 L9,3 z" fill="#000" /></marker><filter id="executeFilter" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feComponentTransfer in="SourceAlpha"><feFuncR type="discrete" tableValues="1"/><feFuncG type="discrete" tableValues="1"/><feFuncB type="discrete" tableValues="0"/></feComponentTransfer><feGaussianBlur stdDeviation="2"/><feOffset dx="0" dy="0" result="shadow"/><feComposite in="SourceGraphic" in2="shadow" operator="over"/></filter>';

    this.element.prepend(defs);
  }

  addBlock(block) {
    this.blocks.push(block);
  }

  addArrow(arrow) {
    this.arrows.push(arrow);
  }

  setVariable(name, value) {
    this.variables.set(name, value);
  }

  getVariable(name) {
    return this.variables.get(name);
  }

  run() {
    if (this.firstBlock) this.firstBlock.execute();
  }
}
