import { updateAttributesSvgElement, createSvgElement } from './svg'

export class Arrow {
  constructor(flowchart, block) {
    this.flowchart = flowchart

    this.block = block

    this.vertices = []
  }

  to(block) {
    this.block.setNextBlock(block)

    block.prevBlock = this.block

    var lastVertex = this.vertices[this.vertices.length - 1]

    block.setPosition(lastVertex.x - 110, lastVertex.y + 10)

    return block
  }

  addVertex(x, y) {
    this.vertices.push({
      x: x,
      y: y
    })
  }

  create() {
    var v2 = this.vertices[0]
    var v1; var line

    var v = this.block.getOutput()

    line = this.createLine(v.x, v.y, v2.x, v2.y)

    for (var i = 0; i < this.vertices.length - 1; i++) {
      v1 = v2
      v2 = this.vertices[i + 1]

      line = this.createLine(v1.x, v1.y, v2.x, v2.y)
    }

    updateAttributesSvgElement(line, {
      'marker-end': 'url(#arrow)'
    })
  }

  createLine(x1, y1, x2, y2) {
    const line = createSvgElement('line')
    updateAttributesSvgElement(line, {
      x1,
      y1,
      x2,
      y2,
      class: 'flowchart-arrow'
    })

    this.flowchart.element.appendChild(line)

    return line
  }
}
