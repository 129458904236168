import { Block } from './block'
import { createSvgElement, updateAttributesSvgElement } from '../svg'

export class TerminalBlock extends Block {
  constructor(text, flowchart) {
    super(flowchart)

    this.text = text
  }

  create() {
    super.create()

    var rect = createSvgElement('rect')
    updateAttributesSvgElement(rect, {
      class: 'flowchart-block',
      x: '0',
      y: '0',
      rx: '30',
      ry: '30',
      style: `width: ${this.width}px; height: ${this.height}px;`
    })

    var text = createSvgElement('text')
    updateAttributesSvgElement(text, {
      class: 'flowchart-text flowchart-terminal-text'
    })
    text.textContent = this.text

    this.flowchart.element.appendChild(this.element)

    this.element.appendChild(rect)
    this.element.appendChild(text)

    var boundingClientRect = text.getBoundingClientRect()

    updateAttributesSvgElement(text, {
      x: 110 - boundingClientRect.width / 2,
      y: boundingClientRect.height / 4 + 35
    })
  }
}
