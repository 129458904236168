import { TerminalBlock } from './blocks/terminal'
import { InputBlock } from './blocks/input'
import { OutputBlock } from './blocks/output'
import { ProcessBlock } from './blocks/process'
import { DecisionBlock } from './blocks/decision'

export class Factory {
  constructor(flowchart) {
    this.flowchart = flowchart
  }

  terminal(text) {
    var block = new TerminalBlock(text, this.flowchart)

    this.flowchart.addBlock(block)

    return block
  }

  input(name) {
    var block = new InputBlock(name, this.flowchart)

    this.flowchart.addBlock(block)

    return block
  }

  output(text) {
    var block = new OutputBlock(text, this.flowchart)

    this.flowchart.addBlock(block)

    return block
  }

  process(text) {
    var block = new ProcessBlock(text, this.flowchart)

    this.flowchart.addBlock(block)

    return block
  }

  decision(text) {
    var block = new DecisionBlock(text, this.flowchart)

    this.flowchart.addBlock(block)

    return block
  }

  start() {
    return this.terminal('Start')
  }

  end() {
    return this.terminal('End')
  }
}
