import React, { useEffect, useRef } from "react";
import Highlight from "react-highlight.js";
import "./App.css";
import Flowchart from "./flowchart";

const CODE = `var n = prompt();
var f = 1;
for (var i = 1; i < n; i++) f = f * i;
alert(f);`;

function App() {
  const svgRef = useRef<SVGSVGElement>(null);
  const flowchartRef = useRef<Flowchart>();

  useEffect(() => {
    const flowchart = new Flowchart(svgRef.current);

    var inputBlock = flowchart.add.input("n");
    var processBlock1 = flowchart.add.process("i = 1");
    var processBlock2 = flowchart.add.process("f = 1");
    var decisionBlock = flowchart.add.decision("i > n");
    var processBlock3 = flowchart.add.process("f = f * i");
    var processBlock4 = flowchart.add.process("i = i + 1");
    var outputBlock = flowchart.add.output("n + '! = ' + f");
    var endBlock = flowchart.add.end();

    flowchart.firstBlock.setPosition(10, 10);

    flowchart.firstBlock
      .addArrow(120, 120)
      .to(inputBlock)
      .addArrow(120, 220)
      .to(processBlock1)
      .addArrow(120, 320)
      .to(processBlock2)
      .addArrow(120, 450, 255, 450)
      .to(decisionBlock);

    decisionBlock
      .onFalseOutput()
      .addArrow(290, 95, 290, 220, 450, 220, 450, 250)
      .to(processBlock3)
      .addArrow(450, 350)
      .to(processBlock4)
      .addArrow(450, 450, 265, 450, 265, 20, 450, 20, 450, 30)
      .to(decisionBlock);

    decisionBlock
      .onTrueOutput()
      .addArrow(595, 95, 595, 470, 480, 470, 480, 480)
      .to(outputBlock)
      .addArrow(480, 570)
      .to(endBlock);

    flowchart.build();

    flowchart.listenOutput = function (msg) {
      alert(msg);
    };

    flowchart.listenInput = function (input, name) {
      input.set(prompt("Get " + name + ":"));
    };

    flowchartRef.current = flowchart;
  }, [svgRef]);

  const run = () => {
    if (flowchartRef.current) flowchartRef.current.run();
  };

  return (
    <div className="App">
      <div className="App-container">
        <div className="App-header App-item">
          <div className="App-card">
            <div className="App-select">
              <select value="factorial">
                <option value="factorial">Factorial</option>
              </select>
            </div>

            <button className="App-button" onClick={run}>
              Run
            </button>
          </div>
        </div>

        <div className="App-left App-item">
          <div className="App-card">
            <Highlight language="javascript">{CODE}</Highlight>
          </div>
        </div>

        <div className="App-right App-item">
          <div className="App-card">
            <svg ref={svgRef} width="100%" height="100%" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
